import React from 'react';



function EfantLabel() {



    return (

                <div className="tm-site-header">
                    <i className="fas fa-shopping-cart fa-3x tm-site-logo"></i>
                    <h1 className="tm-site-name">e-fant App</h1>
                </div>

    );
}

export default EfantLabel;